<template>
  <div class="container-fluid">
    <Header />
    <div class="mx-auto mt-1 content-outer-container">
      <div class="wrap-UserLevel">
        <div class="mx-auto content-inner-container border-header">
          <div class="col-12 title-header pl-4">
            <div @click="showModal()" class="back-btn mr-3">
              <img src="@/assets/images/userlevel/arrow_back.svg" alt="" />
            </div>
            {{ lbl['user-level-create-member-level'] }}
          </div>
        </div>
        <div class="user-body col-12">
          <div class="body-container">
            <StepProgress
              :step="1"
              :header="progressHeader"
              :desc="progressDesc"
            ></StepProgress>
            <div class="body-main">
              <div class="main-header">
                {{ lbl['user-level-please-select'] }}
              </div>
              <div class="main-content">
                <ul
                  class="selection-container"
                  :class="{ 'disabled-update': isActive }"
                  v-for="item in selectionChoice"
                  :key="item.id"
                >
                  <li
                    @click="selectItem(item.id)"
                    class="selection-item"
                    :class="{ selected: item.id === selected }"
                  >
                    <div class="selection-icon">
                      <img
                        :src="
                          require(`../../assets/images/userlevel/${item.img}`)
                        "
                        alt=""
                      />
                    </div>
                    <div class="selection-header">{{ item.name }}</div>
                    <div class="selection-desc">{{ item.desc }}</div>
                  </li>
                </ul>
              </div>
              <div class="set-display-conatainer">
                <div class="title">
                  <strong>
                    {{ lbl['user-level-display-setting-title'] }}
                  </strong>
                  <a-tooltip placement="bottom">
                    <template slot="title">
                      <span>
                        {{ lbl['user-level-display-setting-tooltip'] }}
                      </span>
                    </template>
                    <a-icon type="info-circle" class="info-icon" />
                  </a-tooltip>
                </div>
                <a-switch
                  v-model="displayUserLavel"
                  :checked-children="
                    lbl['user-level-display-setting-title-switch-btn']
                  "
                  :un-checked-children="
                    lbl['user-level-display-setting-title-switch-btn']
                  "
                  default-checked
                />
              </div>
            </div>
          </div>
        </div>
        <div class="user-footer col-12 end">
          <b-button variant="warning" class="userlevel-btn" @click="next()">
            {{ lbl['user-level-button-next'] }}
            <img src="@/assets/images/userlevel/arrow_forward.svg" alt="" />
          </b-button>
        </div>
      </div>
    </div>
    <ModalCancle />
  </div>
</template>

<script>
// mixin
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
// components
import Header from '@/components/Layout/Header'
import StepProgress from '@/module/UserProfile/components/StepProgress.vue'
import ModalCancle from '@/module/UserProfile/modal/CancleModal.vue'

export default {
  name: 'StepOne',
  components: {
    Header,
    StepProgress,
    ModalCancle,
  },
  mixins: [Mixin],
  data: function () {
    return {
      currenttitle: null,
      current: null,
      parent: null,
      progressHeader: '',
      progressDesc: '',
      selectionChoice: [],
      selected: '1',
      displayUserLavel: true,
      dataUserLevel: {},
      isActive: false,
    }
  },
  created() {
    this.handleFooter(true)
    this.handleLoading(false)
    Account.bzbsAnalyticTracking(
      'member_level_step1',
      'member_level',
      'view_member_level_step1',
      'on view',
    )
    this.init()
  },
  methods: {
    init() {
      this.currenttitle = this.lbl['user-level-member-level']
      this.current = this.lbl['user-level-create-member-level']
      this.progressHeader = this.lbl['user-level-member-level-by']
      this.progressDesc = this.lbl['user-level-member-level-by-detail']
      this.selectionChoice = [
        {
          id: '1',
          name: this.lbl['user-level-earning-point'],
          desc: this.lbl['user-level-point-hunter'],
          img: 'loupe.svg',
        },
        {
          id: '2',
          name: this.lbl['user-level-total-spending'],
          desc: this.lbl['user-level-most-spending-win'],
          img: 'brightness_low.svg',
        },
      ]

      this.dataUserLevel = JSON.parse(localStorage.getItem('dataUserLevel'))
      if (this.dataUserLevel != undefined) {
        if (this.dataUserLevel.levelBy != undefined) {
          this.selected = this.dataUserLevel.levelBy
        }
        if (this.dataUserLevel.isActive != undefined) {
          this.isActive = this.dataUserLevel.isActive
        }
        if (this.dataUserLevel.displayUserLavel != undefined) {
          this.displayUserLavel = this.dataUserLevel.displayUserLavel
        }
      } else {
        this.$router.push({
          name: 'CreateUserLevel',
        })
      }
    },
    selectItem(selected) {
      this.selected = selected
    },
    showModal() {
      Account.bzbsAnalyticTracking(
        'member_level_step1',
        'member_level',
        'click_member_level_step1_exit',
        'on click',
      )
      this.$bvModal.show('modalCancle')
    },
    next() {
      Account.bzbsAnalyticTracking(
        'member_level_step1',
        'member_level',
        'click_member_level_step1_next',
        'on click',
      )
      if (this.dataUserLevel.isEdit != undefined) {
        if (this.dataUserLevel.isEdit == true) {
          this.dataUserLevel.isUpdate = true
        }
      }
      this.dataUserLevel.levelBy = this.selected
      this.dataUserLevel.displayUserLavel = this.displayUserLavel
      localStorage.setItem('dataUserLevel', JSON.stringify(this.dataUserLevel))
      this.$router.replace({
        name: 'StepTwo',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/module/UserProfile/UI/BaseStyle.scss';
</style>
